<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
    <path
      :style="iconStyle"
      d="M19.5,9.352H15.216V3H8.784V9.352H4.5L12,16.763Zm-15,9.53V21h15V18.881Z"
      transform="translate(-4.5 -3)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '18',
    },
    width: {
      type: String,
      default: '15',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
